<template>
  <div />
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'SigninOidc',
    mounted() {
      this.oidcSignInCallback()
        .then(redirectPath => {
          this.$router.push(redirectPath)
        })
        .catch(err => {
          console.error(err)
          this.$router.push('/error-401')
        })
    },
    methods: {
      ...mapActions({
        oidcSignInCallback: 'oidc/oidcSignInCallback',
      }),
    },
  }
</script>
